
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import configs from '@/configs'
import { setSobeiruiAuth, getSobeiruiAuth } from '@/utils/cookies'

@Component({
  name: 'Sobeirui',
  mixins: [GlobalMixin]
})
export default class SobeiruiPage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false
  @Provide() public thirdId: string = ''
  @Provide() public channel: string = ''

  async created () {
    this.thirdId = getCurrentQuery('sodexo_openid')
    this.channel = getCurrentQuery('channel')
    if (getSobeiruiAuth()) {
      this.doAuth()
    } else {
      this.showLogin = true
    }
  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setSobeiruiAuth()
    this.doAuth()
  }

  @Emit()
  public async doAuth () {
    const data: any = {
      ThirdId: this.thirdId,
      grant_type: 'Auth_Sobeirui',
      Channel: this.channel
    }
    try {
      const res = await httpHelper.post({
        url: 'authorize/sobeirui',
        contentType: 'application/x-www-form-urlencoded',
        data: data,
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      location.replace(res.value.extend.gotoUrl)
      // this.codeExchangeUrl('1eR1')
    } catch (err) {
      // sessionStorage.setItem('goBackUrl', location.href)
      this.goTo(err, 9, 17)
      this.$toasted.show(err.msg || err.message)
    }
  }
}
